import React from 'react'
import { hot } from 'react-hot-loader/root' // eslint-disable-line import/no-extraneous-dependencies
import { HashRouter } from 'react-router-dom'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import ThemeProvider from 'sav-features/ThemeProvider/ThemeProvider'
import config from 'common/config/auth0.json'
import history from 'sav-utils/history'
import AppScreen from 'app-screen/AppScreen'
import * as Sentry from '@sentry/react'
import ErrorScreen from 'sav-features/error-screen/ErrorScreen'
import AppScreenWrapper from 'sav-features/app-screen/AppScreenWrapper'
import { PortalName } from 'sav-features/app-screen/AppContextProvider'

type OnRedirectCallbackProps = (appState: AppState) => void

const onRedirectCallback: OnRedirectCallbackProps = (appState) => {
	history.push({
		pathname:
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname,
		search: '',
	})
}

const App = () => (
	<Auth0Provider
		audience={config.audience}
		clientId={config.clientId}
		domain={config.domain}
		onRedirectCallback={onRedirectCallback}
		redirectUri={window.location.href}
	>
		<ThemeProvider type="light">
			<HashRouter>
				<Sentry.ErrorBoundary
					fallback={
						<ErrorScreen
							error="Error"
							message="Sorry, there has been an error"
							type="error"
						/>
					}
					showDialog
				>
					<AppScreenWrapper portalName={PortalName.Denmark}>
						<AppScreen />
					</AppScreenWrapper>
				</Sentry.ErrorBoundary>
			</HashRouter>
		</ThemeProvider>
	</Auth0Provider>
)

const isEnvDev = process.env.NODE_ENV === 'development'

export default isEnvDev ? hot(App) : App
